<template>
  <el-dialog
    id="EditRestrictDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="496px"
    close-on-press-escape
    class="my-dialog abow_dialog"
    @opened="onShow"
    destroy-on-close
    @keydown.enter.native="onCommit"
  >
    <el-form ref="EditRestrictForm" :model="model" :rules="rules" @submit.native.prevent="onSubmit" v-loading="loading">
      <el-row v-if="loading" justify="center" align="middle" type="flex" style="height:430px">
        <el-empty :image-size="100" :image="require('../../../assets/icons/no_data.svg')"></el-empty>
      </el-row>
      <el-row v-else>
        <el-form-item prop="name" :label="$t('Назва умови')">
          <el-input v-model="model.name" ref="nameInput" autocomplete="off"></el-input>
        </el-form-item>
        <el-row justify="space-between" type="flex">
          <el-col :span="11">
            <el-form-item prop="beginDate" :label="$t('Початок')">
              <el-date-picker type="date" format="dd.MM.yyyy" v-model="model.beginDate" style="width:100%"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="endDate" :label="$t('Кінець')">
              <el-date-picker type="date" format="dd.MM.yyyy" v-model="model.endDate" style="width:100%"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="type" :label="$t('Тип умови')">
          <el-select style="width:100%" v-model="model.type">
            <el-option v-for="item in selectOptions.type" :key="item.value" :label="$t(item.label)" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <div v-if="model.type != 'action'">
          <el-form-item prop="planMeasure" :label="$t('План, одиниці вимірювання')">
            <el-select style="width:100%" v-model="model.planMeasure">
              <el-option v-for="item in selectOptions.planMeasure" :key="item.value" :label="$t(item.label)" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="bonusMeasure" :label="$t('Бонус, одиниці вимірювання')">
            <el-select style="width:100%" v-model="model.bonusMeasure">
              <el-option v-for="item in selectOptions.bonusMeasure" :key="item.value" :label="$t(item.label)" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item prop="notes" :label="$t('Примітка')">
          <el-input type="textarea" v-model="model.notes" autocomplete="off" :rows="model.type == 'action' ? 5 : 1"></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
    <FindOrg @find-commit="onFindCommit" />
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";

export default {
  name: "EditRestrict",
  components: { FindOrg: () => import("@/components/forms/FindOrg") },
  data() {
    var that = this;
    var defaultValidateRule = { required: true, message: "Це поле є необхідним" };
    var checkBeginDate = (rule, value, callback) => {
      if (value && that.model.endDate) {
        if (new Date(value) > new Date(that.model.endDate)) callback(new Error("Дата початку пізніше дати кінця"));
      }
      callback();
    };
    var checkEndDate = (rule, value, callback) => {
      if (value && that.model.beginDate) {
        if (new Date(value) < new Date(that.model.beginDate)) callback(new Error("Дата кінця раніше дати початку"));
      }
      callback();
    };
    return {
      loading: false,
      dialogShow: false,
      title: "Створення нового договору",
      operation: null,
      model: {},
      initModel: {},
      rules: {
        name: [defaultValidateRule],
        type: [defaultValidateRule],
        planMeasure: [defaultValidateRule],
        bonusMeasure: [defaultValidateRule],
        beginDate: [
          defaultValidateRule,
          {
            validator: checkBeginDate,
          },
        ],
        endDate: [
          defaultValidateRule,
          {
            validator: checkEndDate,
          },
        ],
      },
    };
  },

  methods: {
    findOrg(event, id) {
      switch (id) {
        case "customer": {
          bus.$emit("forms.orgs.find.show", { target: "customer", title: "Пошук організації замовника", singleRecord: true });
          break;
        }
        case "executor": {
          bus.$emit("forms.orgs.find.show", { target: "executor", title: "Пошук організації виконавця", singleRecord: true });
          break;
        }
        case "providers": {
          bus.$emit("forms.orgs.find.show", {
            target: "providers",
            title: "Пошук організації постачальників",
            singleRecord: false,
            selectedOrgs: this.model.providers,
          });
          break;
        }
        default:
          break;
      }
    },
    onFindCommit(data) {
      switch (data.target) {
        case "customer": {
          this.customerComputed = data.result[0];
          break;
        }
        case "executor": {
          this.executorComputed = data.result[0];
          break;
        }
        case "providers": {
          this.providersComputed = data.result;
          break;
        }
        default:
          break;
      }
    },
    onCommit() {
      this.$refs.EditRestrictForm.validate((valid) => {
        if (valid) {
          this.$emit("commit", { operation: this.operation, model: this.model });
        } else {
          this.$message.error($tt("Перевірте введені дані!"));
        }
        return false;
      });
    },
    onShow() {
      this.$refs.EditRestrictForm.resetFields();
      this.model = _.extend({}, this.initModel);
      this.$nextTick(() => {
        this.loading = false;
        //setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.nameInput.focus();
        });
      });
    },
  },
  created() {
    bus.$on("forms.restricts.edit.show", (data) => {
      this.loading = true;
      this.title = (data && data.title) || this.title;
      this.operation = (data && data.operation) || "create";
      this.initModel = _.extend({}, data ? data.initModel : {});
      this.dialogShow = true;
    });
    bus.$on("forms.restricts.edit.hide", () => {
      this.dialogShow = false;
    });
  },
  computed: {
    selectOptions() {
      return this.$store.getters["main/selectionOptions"].restricts;
    },
    customerComputed: {
      get() {
        if (!this.model.customer) return null;
        else return this.model.customer.name;
      },
      set(value) {
        this.model.customer = value;
      },
    },
    executorComputed: {
      get() {
        if (!this.model.executor) return null;
        else return this.model.executor.name;
      },
      set(value) {
        this.model.executor = value;
      },
    },
    providersComputed: {
      get() {
        if (!this.model.providers) return null;
        else {
          if (this.model.providers.length > 1) return `${this.model.providers[0].name} (+ще ${this.model.providers.length - 1})`;
          else if (this.model.providers.length == 1) return `${this.model.providers[0].name}`;
          else return null;
        }
      },
      set(value) {
        this.model.providers = value;
      },
    },
    providersComputedTooltip() {
      if (!this.model.providers) return "None";
      else {
        return this.model.providers
          .map((item) => {
            return item.name;
          })
          .join(", ");
      }
    },
  },
};
</script>
<style lang="scss">
#EditRestrictDialog {
  .el-dialog {
    max-height: 570px !important;
  }
}
</style>
